import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "row",
    "groupSubtotal",
    "quantity",
    "margin",
    "unitPrice",
    "total",
  ];

  connect() {
    // Listen for a custom event that signals when to recalculate the group subtotal
    this.element.addEventListener(
      "recalculate-subtotal",
      this.calculateGroupSubtotal.bind(this),
    );
  }

  disconnect() {
    // Clean up by removing the event listener when the controller disconnects
    this.element.removeEventListener(
      "recalculate-subtotal",
      this.calculateGroupSubtotal.bind(this),
    );
  }

  // Calculate and update the group subtotal
  calculateGroupSubtotal() {
    let groupSubtotal = 0;
    const groupSubTotalField = document.getElementById("group____subtotal");

    // Iterate through all rows and sum the subtotals
    this.rowTargets.forEach((row) => {
      const subtotalText = row.querySelector(".total").textContent;
      const subtotal = parseFloat(subtotalText.replace(/,/g, "")) || 0; // Remove commas and parse
      groupSubtotal += subtotal;
    });

    // Update the displayed group subtotal
    if (groupSubTotalField) {
      groupSubTotalField.textContent = new Intl.NumberFormat().format(
        groupSubtotal.toFixed(2),
      );
    }
  }
}
