import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "status",
    "estimateStatus",
    "estimateStatusHistory",
    "historyStatusDropdown",
    "userName",
    "bomWoInfo",
    "workOrder",
    "quantity",
    "difficulty",
    "margin",
    "unitPrice",
    "includeUnitPrice",
    "total",
    "itemDisplayInTotals",
  ];

  submitForm(event) {
    var field = event.target;
    this.element.form.requestSubmit();
    this.currentValue = field.value;
  }

  toggleWorkOrderInfoField(event) {
    const estimate_id = event.target.dataset.value;
    const selected_status = event.target.value;

    this.updateBomWoInfo(selected_status, estimate_id);
    this.updateUserField(selected_status, estimate_id);
  }

  toggleWorkOrderField(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
    this.setWorkOrderField(estimate_id).classList.remove("d-none");
  }

  showeWorkOrderInfo(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    this.setWorkOrderDropdown(estimate_id).value = "";
    this.setWorkOrderField(estimate_id).classList.add("d-none");
  }

  updateBomWoInfo(selected_status, estimate_id) {
    if (selected_status === "confirmed") {
      this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    } else {
      this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
      this.setWorkOrderField(estimate_id).classList.add("d-none");
    }
  }

  updateUserField(selected_status, estimate_id) {
    if (selected_status === "approved" || selected_status === "declined") {
      this.setUserField(estimate_id).classList.remove("d-none");
      this.setByLabel(estimate_id);
      this.updateLabel(estimate_id, selected_status);
    } else {
      this.setUserField(estimate_id).classList.add("d-none");
      this.setByField(estimate_id).value = "";
      this.setDateField(estimate_id).value = new Date()
        .toISOString()
        .split("T")[0];
    }
  }

  updateLabel(estimate_id, selected_status) {
    const byLabelElement = this.setByLabel(estimate_id);

    // Change the text content based on some condition
    if (byLabelElement) {
      if (selected_status === "approved") {
        byLabelElement.textContent = "Approved by"; // Change text for approved status
      } else if (selected_status === "declined") {
        byLabelElement.textContent = "Declined by"; // Change text for declined status
      }
    }
  }

  setBomWoInfoBlock(estimate_id) {
    return this.element.querySelector("#wo_bom_info_" + estimate_id);
  }

  setWorkOrderField(estimate_id) {
    return this.element.querySelector("#existing_wo_" + estimate_id);
  }

  setWorkOrderDropdown(estimate_id) {
    return this.element.querySelector("#work_order_dropdown_" + estimate_id);
  }

  setUserField(estimate_id) {
    return this.element.querySelector("#user_field_estimate_" + estimate_id);
  }

  setByField(estimate_id) {
    return this.element.querySelector("#by_estimate_" + estimate_id);
  }

  setDateField(estimate_id) {
    return this.element.querySelector("#date_estimate_" + estimate_id);
  }

  setByLabel(estimate_id) {
    return this.element.querySelector("#by_label_" + estimate_id);
  }

  // Estimate Template JS

  toggleVisibility(event) {
    event.preventDefault();
    const shouldShow = event.target.closest("button").dataset.displayInTotals;
    const displayInTotalField = document.getElementById(
      "item_display_in_totals",
    );

    displayInTotalField.value = shouldShow;

    // Toggle button visibility
    event.target.parentNode.parentElement
      .querySelector(".show_item")
      .classList.toggle("d-none");
    event.target.parentNode.parentElement
      .querySelector(".hide_item")
      .classList.toggle("d-none");
  }

  // Calculate the total when any of the values change
  updateItemTotal(event) {
    const row = event.target.closest("tr");
    const quantity = parseFloat(row.querySelector(".quantity").value) || 0;
    const margin = parseFloat(row.querySelector(".margin").value) || 0;
    const unitPrice = row.querySelector(".unitPrice");
    const includeUnitPrice = row.querySelector(".includeUnitPrice").checked;
    const productSellingPrice = parseFloat(
      row.querySelector("#product_selling_price").value,
    );
    const productShippingCost = parseFloat(
      row.querySelector("#product_shipping_cost").value,
    );

    // Calculate total price based on the formula
    let subtotal = 0;

    if (includeUnitPrice) {
      subtotal =
        quantity *
          (productSellingPrice + (productSellingPrice * margin) / 100) +
        productShippingCost;
    } else {
      subtotal = 0;
    }

    // Update the total in the UI
    row.querySelector(".total").textContent = new Intl.NumberFormat().format(
      subtotal.toFixed(2),
    );
    unitPrice.textContent = new Intl.NumberFormat().format(subtotal.toFixed(2));

    // Optionally, update the hidden field for display_in_totals if needed
    const displayInTotalsField = row.querySelector(".item_display_in_totals");
    displayInTotalsField.value = includeUnitPrice ? "true" : "false";
  }
}
